export default {
    promos: {
        points: 'πόντοι'
    },
    leaderBoard: {
        myPoints: 'Οι πόντοι μου',
        notPlatWinners: 'Δεν υπάρχουν ακόμα νικητές στο Platinum επίπεδο και το μεγάλο έπαθλο των',
        platWinners: 'Νικητές επιπέδου Platinum: ',
        reward: 'Έπαθλο ανά Νικητή : ',
        platinumWinners: 'Νικητές Platinum'
    },
    historyCalendar: {
        previousWeek: 'Προηγ. Εβδομάδα',
        nextWeek: 'Επόμ. Εβδομάδα',
        monday: 'Δευτέρα',
        tuesday: 'Τρίτη',
        wednesday: 'Τετάρτη',
        thursday: 'Πέμπτη',
        friday: 'Παρασκευή',
        saturday: 'Σάββατο',
        sunday: 'Κυριακή',
        totalDailyPoints: 'Συνολικοί Πόντοι Ημέρας',
        totalWeekPoints: 'Συνολικοί Πόντοι Εβδομάδας',
        activationWeek: 'ΠΡΟΣΦΟΡΕΣ',
        points: 'ΠΟΝΤΟΙ',
        standardWheel: 'MAGIC WHEEL',
        premiumWheel: 'PREMIUM WHEEL'
    },
    navbar: {
        pamestoixima: 'PAMESTOIXIMA',
        stoixima: 'ΣΤΟΙΧΗΜΑ',
        liveStoixima: 'LIVE ΣΤΟΙΧΗΜΑ',
        virtuals: 'VIRTUALS+',
        casino: 'CASINO',
        slots: 'SLOTS',
        liveCasino: 'LIVE CASINO',
        games: 'GAMES',
        visit: 'Επισκεφτείτε το',
        promotions: 'Προσφορές',
        casinoPromos: 'ΠΡΟΣΦΟΡΕΣ CASINO',
        casinoPromosDescription: 'Εδώ βλέπεις όλες τις διαθέσιμες προσφορές',
        opapWorld: 'OPAP World'
    },
    opapFooter: {
        drawsResults: 'Κληρώσεις & Αποτελέσματα',
        support: 'Υποστήριξη',
        company: 'Εταιρία',
        aboutUs: 'Σχετικά με εμάς',
        aboutUsLink: 'https://club.pamestoixima.gr/el/h-etairia',
        casinoGames: 'Κανόνες',
        casinoGamesLink: 'https://club.pamestoixima.gr/el/rules-casino',
        usefulLinks: 'Χρησιμα links',
        complaints: 'Μέθοδοι Συναλλαγών',
        complaintsLink: 'https://club.pamestoixima.gr/el/methodoi-sunallagwn',
        parapona: 'Παράπονα',
        paraponaLink: 'https://club.pamestoixima.gr/parapona-kataggelies',
        termsAndConditions: 'Όροι & Προϋποθέσεις',
        termsAndConditionsLink: 'https://club.pamestoixima.gr/el/symvasi-prosxwrisis',
        privacyPolicy: 'Προσωπικά Δεδομένα',
        privacyPolicyLink: 'https://club.pamestoixima.gr/el/proswpika-dedomena',
        cookiesPolicy: 'Πολιτική Cookies',
        cookiesPolicyLink: 'https://club.pamestoixima.gr/el/politiki-cookies',
        contactUs: 'Επικοινωνία',
        contactUsLink: 'https://media.pamestoixima.gr/gr/24-7-eksipiretisi-pelaton.html',
        glossary: 'Παιχνίδια Καζίνο',
        glossaryLink: 'https://casino.pamestoixima.gr/games',
        transactionsMethods: 'Γλωσσάρι',
        transactionsMethodsLink: 'https://club.pamestoixima.gr/el/glossary-casino',
        faqs: 'Συχνές Ερωτήσεις',
        faqsLink: 'https://club.pamestoixima.gr/el/syxnes-erwtiseis',
        responsibleGaming: 'Υπεύθυνο Παιχνίδι',
        responsibleGamingLink: 'https://club.pamestoixima.gr/el/ypeuthino-paixnidi',
        sponsors: 'Χορηγοί',
        paymentMethods: 'Τρόποι Πληρωμής',
        accountVerification: 'Ταυτοποίηση',
        accountVerificationLink: 'https://club.pamestoixima.gr/el/tautopoihsh-logariasmou',
        rules: 'Φορολογία Κερδών',
        rulesLink: 'https://club.pamestoixima.gr/el/taxation-casino',
        language: 'Γλώσσα (Language)',
        eeepNotice: 'Ο διαχειριστής αυτής της ιστοσελίδας είναι η εταιρεία με την επωνυμία «Οργανισμός Προγνωστικών Αγώνων Ποδοσφαίρου Α.Ε.» και τον διακριτικό τίτλο «Ο.Π.Α.Π. Α.Ε.», η οποία εδρεύει στην Ελλάδα, Αθήνα, επί της οδού Λεωφόρος Αθηνών 112, με αριθμό μητρώου 46329/06 / B / 00/15 και αριθμό Γ.Ε.ΜΗ 003823201000. . Η Ο.Π.Α.Π. Α.Ε. εποπτεύεται από την Επιτροπή Εποπτείας &amp; Ελέγχου Παιγνίων (Ε.Ε.Ε.Π.) και έχει λάβει άδεια διεξαγωγής τυχερών παιγνίων μέσω διαδικτύου τύπου 2 με κωδικό HGC-008-LH, για τη διοργάνωση λοιπών διαδικτυακών παιγνίων, με ισχύ από την 25η Μαΐου 2021 έως την 25η Μαΐου 2028. Αρμόδιος ρυθμιστής ΕΕΕΠ. Η συμμετοχή σε τυχερά παίγνια επιτρέπεται μονό σε άτομα άνω των 21 ετών. Η συχνή συμμετοχή ενέχει κίνδυνο εθισμού και απώλειας περιουσίας. Γραμμή Στήριξης: 1114 Γιατί το παιχνίδι το ελέγχεις εσύ και ο ΟΠΑΠ σε βοηθάει να μάθεις πως . ΟΠΑΠ παίξε Υπεύθυνα',
        hccRegulated: 'ΑΡΜΟΔΙΟΣ ΡΥΘΜΙΣΤΗΣ ΕΕΕΠ',
        riskOfAddiction: 'ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ',
        helpLine: 'ΓΡΑΜΜΗ ΒΟΗΘΕΙΑΣ ΚΕΘΕΑ: 1114',
        smallHelpLine: 'ΚΕΘΕΑ: 1114',
        playResponsibly: 'ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ',
        copyright: '© 2023 ΟΠΑΠ.Ισχύουν όροι και προϋποθέσεις'
    },
    tier: {
        title: 'To επίπεδο μου',
        bronze: 'Χάλκινο',
        silver: 'Ασημένιο',
        gold: 'Χρυσό',
        platinum: 'Πλατινένιο',
        myPoints: 'Οι πόντοι μου',
        pointsToNextLevel: 'Πόντοι για το επόμενο επίπεδο',
        prize: 'Έπαθλο'
    },
    magicWheel: {
        routeTitle: 'Magic Wheel',
        standard: 'Magic',
        premium: 'Premium',
        wheel: 'Τροχος',
        monday: 'Δευτέρα',
        tuesday: 'Τρίτη',
        wednesday: 'Τετάρτη',
        thursday: 'Πέμπτη',
        friday: 'Παρασκευή',
        saturday: 'Σάββατο',
        sunday: 'Κυριακή',
        points: 'ΠΟΝΤΟΙ',
        freeSpins: 'FREE SPINS',
        goldenChips: 'GOLDEN CHIPS',
        learnMore: 'μάθε περισσότερα',
        freeBets: 'FREE BETS'
    },
    howToPlay: {
        text: `<div><p>
        Ο Τυχερός Τροχός σε επιβραβεύει καθημερινά με εγγυημένα έπαθλα, εντελώς δωρεάν!
    </p>
    <p>
        <strong>Προϋποθέσεις  συμμετοχής</strong>
    </p>
    <p>
        Το  μόνο που έχεις να κάνεις για να ξεκλειδώσεις  τον τροχό είναι το εξής:
    </p>
    <p>
        Αν  η πρώτη σου κατάθεση εντός της ημεράς,  είναι <u>από 20€ έως 49,99€</u>
        ενεργοποιείς  τον Lucky Wheel. <br/>
        Αν η κατάθεσή σου είναι <u>άνω των 50€,</u> ενεργοποιείς τον Premium  Wheel
        και διεκδικείς ακόμα μεγαλύτερα  έπαθλα!
    </p>
    <p>
        Ο  Τροχός μπορεί να ενεργοποιηθεί <u>μία  φορά, κάθε ημέρα</u>! Αλλά πρέπει
        να ληφθεί  υπόψιν ότι ο τροχός ενεργοποιείται  εντός 60 λεπτών μετά την
        κατάθεση του  παίκτη και είναι ενεργός μέχρι τις 17:00  μμ. της επόμενης
        ημέρας. Μετά τις 17:00 μμ.  της επόμενης ημέρας, ο τροχός  απενεργοποιείται
        και ο παίκτης χάνει  την δυνατότητα της ημερήσιας περιστροφής.
    </p>
    <p>
        <strong>Ανταμοιβές  τροχών:</strong>
    </p>
    <p>
        Με  την ελάχιστη κατάθεση (20€ - 49.99€),  ενεργοποιείται ο
        <u>
            Lucky Wheel
        </u>
        και διεκδικείς:
    </p>
    <ul>
        <li>
            <p>
                Έως          10 Free Spins στο          Βιg Bass Mission Fishin
            </p>
        </li>
        <li>
            <p>
                Έως          10 Golden Chips στα αποκλειστικά τραπέζια
            </p>
        </li>
        <li>
            <p>
                Έως          2€ Free Bet στο Αθλητικό Στοίχημα
            </p>
        </li>
    </ul>
    <p>
        Αντίστοιχα,  στον <u>Premium Wheel</u> (κατάθεση 50€+) διεκδικείς:
    </p>
    <ul>
        <li>
            <p>
                Έως          50 Free Spins στο          Big Bass Mission Fishin
            </p>
        </li>
        <li>
            <p>
                Έως          50 Golden Chips στα αποκλειστικά τραπέζια
            </p>
        </li>
        <li>
            <p>
                Έως          5€ Free Bet στο Αθλητικό Στοίχημα
            </p>
        </li>
    </ul>
    <p>
        Τα  έπαθλα από τον τροχό πιστώνονται στον  λογαριασμό σου, αυτόματα εντός 24
        ωρών.
    </p></div>`
    }
}
